let Environment = {
  ControlAbi: '0x73210fBe16461929735Eb7ea114924A6eAf7573C',
  intialMintV2: '0xA2268Fcc2FE7A2Bb755FbE5A7B3Ac346ddFeDB9B',
  EarnAbi: '0xb11a77bB8fA1ad5E4bF3aDC146cD5968F7fA3fcD',
  FarmAbi: '0x4590BaD61aE62ACFF33032e3Bf64b52b7264A779',
  ReserveAbi: '0xc5161aE3437C08036B98bDb58cfE6bBfF876c177',


  HydtAbi: '0x2cfea85633bd529fa136012a69638331ef05beea',
  HygtAbi: '0x4c1bf1b685873f182833287e7957bf090072ede5',
  ShydtAbi: '0xac6712245bfd2bfb372ae2beb8449cbb27721f0a',

  ControlResolverAbi: '0x902f25c0306cE518B7c123888304Cb67B10Ab6bE',

  base_url: "https://liveapi.opencanvas.app/v1",

  reservesContract: "0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE", //CoinPair BNB-USDT Pair
  quoteContract: "0x10ED43C718714eb63d5aA57B78B54704E256024E", //UniswapV2Router02 same

  routerContract: "0x10ED43C718714eb63d5aA57B78B54704E256024E", // UniswapV2Router02 same
  hydtGraph:
    "https://api.studio.thegraph.com/query/91587/hydtstaking2/0.0.1",
  claim: "0xDe8e71E98E47d640D007200eF88B27e263b7aFe6", // claim | AffiliateWithdrawal contract from afaq//////// Affiliate Withdraw contract with updated HYDT and HYGT addresses
  pancakeHygtHydt: "0xE0e9bBc7aE8EBE4D74065F6EBD710665DA285a0B", //HYDT-BNB
  // apiUrl: "http://ec2-18-236-118-29.us-west-2.compute.amazonaws.com:5555/",
  // apiUrl: "http://ec2-18-236-118-29.us-west-2.compute.amazonaws.com:5555/",
  // apiUrl: "https://hydtapi.quecko.com/",
  apiUrl: "https://hydtauditedapi.quecko.com/",
};

export default Environment;